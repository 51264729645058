import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import type { IGalleryInfo } from '../pages/arrivalGoods/interface';
import api from './api';

/**
   * 大陆手机号码11位数，匹配格式：前三位固定格式+后8位任意数
   * 此方法中前三位格式有：
   * 13+任意数
   * 145,147,149
   * 15+除4的任意数(不要写^4，这样的话字母也会被认为是正确的)
   * 166
   * 17+3,5,6,7,8
   * 18+任意数
   * 198,199
   *
   * 香港手机号码8位数，5|6|8|9开头+7位任意数
   * 前端手机号格式参考了后端
   */
export const phoneReg = /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$|^(5|6|8|9)\d{7}$/;

export const splitPrice = (price: number | string): { intPart: string; decimalPart: string; } => {
  const _price = Number(price);
  if (_price == null) {
    return {
      intPart: '0',
      decimalPart: '00',
    };
  }
  const [
    intPart,
    decimalPart,
  ] = _price.toFixed(2).split('.');
  return {
    intPart,
    decimalPart,
  };
};

export const getGalleryId = async(): Promise<string> => {
  try {
    const res = await request<BaseData<IGalleryInfo>>({ url: api.getGalleryId });
    return Promise.resolve(res?.data?.galleryId);
  } catch (error) {
    return Promise.reject('');
  }
};

export const handleCopy = (text: string): void => {
  const temp = document.createElement('input');
  document.body.appendChild(temp);
  temp.setAttribute('value', text);
  temp.select();
  document.execCommand('copy');
  document.body.removeChild(temp);
  message.success('复制成功');
};
