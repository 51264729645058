import { Button, Input, Modal, Select, Spin } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './index.less';
import ArrivalGoodsModel from './model';

@observer
export default class ArrivalGoods extends Component {
  constructor(props) {
    super(props);
    this.arrivalGoodsModel = new ArrivalGoodsModel();
  }

  private readonly arrivalGoodsModel: ArrivalGoodsModel;

  public render(): ReactNode {
    const { egGridModel, loading, onConfirmArrival, onBindingGalleryOk, onBindingGalleryCancel, setValue, returnSampleCycle, galleryId, promptVisible, bindingGalleryVisible, galleryOptions, uniqueCode, skuNo, color, skuPic, size, onPromptCancel, onPromptOk, setUniqueCode, scanUniqueCode } = this.arrivalGoodsModel;
    return (
      <Spin
        size="large"
        spinning={loading}
        tip="Loading..."
      >
        <div className={styles.page}>
          <div className={styles.sweepYards}>
            <span className={styles.tag}>
              扫码到货
            </span>
            <div className={styles.sweepYardsCode}>
              <span className={styles.promptTitle}>
                请扫描商品条码
              </span>
              <Input
                className={styles.input}
                onChange={(e) => {
                  setUniqueCode(e.target.value);
                }}
                onPressEnter={scanUniqueCode}
                placeholder="商品唯一码"
                size="large"
                value={uniqueCode}
              />
              {skuNo ? (
                <div className={styles.skuNo}>
                  <span>
                    {skuNo}
                  </span>
                  <span>
                    {`${color || ''} ${size || ''}`}
                  </span>
                  <img
                    className={styles.pic}
                    src={skuPic}
                  />
                </div>
              ) : (
                <div className={styles.noData}>
                  暂无数据
                </div>
              )}
            </div>
          </div>
          <div className={styles.results}>
            <div className={styles.resultsTitle}>
              <span>
                扫描结果
              </span>
              <Button
                onClick={(e) => {
                  try {
                    window.top.egenie.openTab('/egenie-ts-gallery/receivingRecord/index', '74', '到货记录');
                  } catch (e) {
                    console.log(e);
                  }
                }}
                type="link"
              >
                到货记录
              </Button>
            </div>
            <div className={styles.arrivalGoods}>
              <div className={styles.arrivalGoodsNum}>
                本次到货数量
                <span>
                  {egGridModel?.rows?.length || 0}
                </span>
                <span>
                  件
                </span>
              </div>
              <Button
                onClick={onConfirmArrival}
                type="primary"
              >
                确认到货
              </Button>
            </div>
            <div className={styles.table}>
              <EgGrid store={egGridModel}/>
            </div>
          </div>
        </div>
        <Modal
          onCancel={onPromptCancel}
          onOk={onPromptOk}
          open={promptVisible}
          title="提示"
          width={328}
        >
          <span>
            该商品未加入展厅，无法操作到货，是否加入 展厅并到货？
          </span>
        </Modal>
        <Modal
          closable={false}
          onCancel={onBindingGalleryCancel}
          onOk={onBindingGalleryOk}
          open={bindingGalleryVisible}
          width={328}
        >
          <div className={styles.bindingGallery}>
            <div>
              <span className={styles.bindingGalleryTitle}>
                所属展厅
              </span>
              <Select
                className={styles.bindingGalleryContent}
                onChange={(value) => {
                  setValue('galleryId', value);
                }}
                options={galleryOptions}
                placeholder="请选择"
                value={galleryId}
              />
            </div>
            <div>
              <span className={styles.bindingGalleryTitle}>
                退样周期
              </span>
              <Input
                className={styles.bindingGalleryContent}
                onChange={(e) => {
                  setValue('returnSampleCycle', e.target?.value?.replace(/[^\d]/g, ''));
                }}
                suffix="天"
                value={returnSampleCycle}
              />
            </div>
          </div>
        </Modal>
      </Spin>
    );
  }
}
