import { getStaticResourceUrl } from 'egenie-common';

const api = {
  // 静态资源
  oss: getStaticResourceUrl('pc/ts/egenie-ts-gallery'),

  // 获取展厅id
  getGalleryId: '/api/gallery/rest/getOwnGallery',

  // 公共接口
  getDict: '/api/bms/bill/dict/getDict', // 字典项
  getDictWarehouse: '/api/bms/bill/dict/getDict/special', // 仓库
  getfinacialDict: '/api/rms/common/getDict',

  // 用户
  anonSend: '/api/iac/validCode/anon/boss/send', // 发送验证码
  modifyPhone: '/api/iac/user/modify/phone', // 修改手机号

  // 选款订单
  queryPageOrder: '/api/mall/rest/saleOrder/pageOrder', // 主表查询
  queryOrderStatus: '/api/mall/rest/saleOrder/status', // 订单状态列表
  queryOrderDetail: '/api/mall/rest/saleOrder/findDetail', // 订单详情
  getVendorList: '/api/mall/rest/saleOrder/getSimpleVendorInfo', // 获取档口列表
  getUsersList: '/api/mall/rest/saleOrder/getUsers', // 获取下单账号
  confirmReceive: '/api/mall/rest/saleOrder/confirmReceive', // 确认收货
  cancelOrder: '/api/mall/rest/saleOrder/cancelOrder', // 取消订单
  deleteOrder: '/api/mall/rest/saleOrder/deleteOrder', // 删除订单
  cancelSaleOrder: '/api/mall/operation/rest/mall/saleOrder/cancelOrder',
  queryChooseOrderStatus: '/api/mall/operation/rest/mall/saleOrder/status', // 顶部tab
  editRemark: '/api/mall/operation/rest/mall/saleOrder/editMallOrderRemark', // 编辑订单备注
  markPriorityDeliver: '/api/mall/operation/rest/mall/saleOrder/markPriorityDeliver', // 采购优先
  markSaleOrderPriorityDeliver: '/api/retail/shop/applet/rest/boss/markPriorityDeliver', // 分销优先

  // 选款订单
  queryStyleOrder: '/api/mall/operation/rest/mall/saleOrder/findPageByParam',
  queryStyleOrderDetail: '/api/mall/operation/rest/mall/saleOrder/findDetail',
  queryReturnGoodsList: (orderId: string | number): string => (`/api/mall/operation/rest/mall/saleOrder/findReturnRightDetailInfo?mallSaleOrderId=${orderId}`), // 获取无忧退货商品列表
  shortageDeliver: '/api/mall/operation/rest/mall/saleOrder/outStock/delivery',
  manuallyShipInfo: (orderId: number) => `/api/mall/operation/rest/mall/saleOrder/getManualDeliverInfo/${orderId}`, // 手动发货详情
  manuallyShip: '/api/mall/operation/rest/mall/saleOrder/confirmManualDeliver', // 手动发货确认
  getReturnOrderRelationSaleOrders: '/api/mall/operation/rest/mall/saleOrder/getReturnOrderRelationSaleOrders',
  getLackStockOrderCount: '/api/mall/operation/rest/mall/saleOrder/getLackStockOrderCount', // 查询近7日已缺货72h订单数量
  getMallSaleOrderSplit: '/api/mall/operation/rest/mall/saleOrder/getMallSaleOrderSplit',
  confirmMallSaleOrderSplit: '/api/mall/operation/rest/mall/saleOrder/confirmMallSaleOrderSplit',
  getCouriers: '/api/mall/operation/rest/mall/saleOrder/getCouriers',
  voidAndReturn: '/api/mall/operation/rest/mall/saleOrder/refundDeliverOrder',

  // 选款分销订单
  querySaleOrder: '/api/retail/shop/applet/rest/boss/findPageByParam',
  querySaleOrderStatus: '/api/retail/shop/applet/rest/order/status',
  querySaleOrderDetail: '/api/retail/shop/applet/rest/boss/findDetail',
  queryVendorList: '/api/pos/baseinfo/rest/admin/shop/findPage', // 请求店铺/供应商列表
  findCourierOrder: '/api/mall/operation/rest/mall/saleOrder/findCourierOrder',
  queryOrderLog: '/api/mall/operation/rest/admin/Log/getLogs',
  queryDistributorList: '/api/retail/shop/applet/rest/boss/findRetailShopPageList', // 分销商列表
  editDistributeRemark: '/api/retail/shop/applet/rest/boss/editRetailOrderRemark', // 分销商列表编辑备注
  manuallyShipDistributeShipInfo: (orderId: number) => `/api/retail/shop/applet/rest/boss/getManualDeliverInfo/${orderId}`, // 分销单
  manuallyShipDistributeShip: '/api/retail/shop/applet/rest/boss/confirmManualDeliver', // 分销确认手动发货
  getSaleLackStockOrderCount: '/api/retail/shop/applet/rest/boss/getLackStockOrderCount', // 查询近7日已缺货72h订单数

  // 展厅管理
  queryShowroomGoods: '/api/gallery/rest/galleryGoods/queryPage', // 展厅列表分页查询
  arrive: '/api/gallery/rest/galleryGoods/confirmReceiveSample', // 确认到样
  confirmAdd: '/api/gallery/rest/galleryGoods/confirmAdd', // 确认加入
  awaitReturnNum: '/api/gallery/rest/galleryGoods/getReturnSample', // 待归还样衣件数
  queryShowroomList: '/api/gallery/rest/galleryGoodsBinding/getGalleryList', // 展厅列表接口
  pushAndWithdraw: '/api/gallery/rest/galleryGoods/shelveOrUnshelve', // 上下架
  queryPrintData: '/api/gallery/rest/galleryGoods/queryPrintInfo', // 获取打印数据
  getPosTemplateInfoByType: '/api/pos/baseinfo/rest/posTemplate/getPosTemplateInfoByType', // 获取打印模板
  queryAreaList: '/api/gallery/rest/galleryGoodsArea/getGalleryAreaList', // 分区列表
  editArea: '/api/gallery/rest/galleryGoodsArea/saveArea', // 新建、编辑分区
  deleteArea: '/api/gallery/rest/galleryGoodsArea/deleteArea', // 删除分区
  batchBindingArea: '/api/gallery/rest/galleryGoodsArea/batch/binding/area', // 批量设置
  queryArriveLog: '/api/gallery/rest/galleryGoodsArrival/queryDetailsByGalleryGoodsId', // 查找到货记录明细
  batchRemove: '/api/gallery/rest/galleryGoods/batchRemove', // 批量移出展厅
  getShoppingCartCount: '/api/gallery/rest/galleryGoods/getShoppingCartCount', // 获取购物车商品数量
  addShoppingCart: '/api/gallery/rest/galleryGoods/addShoppingCart', // 加购物车
  queryUnshelfNum: '/api/gallery/rest/galleryGoods/getPlatformSoldOutGoodsCount', // 获取平台已下架款式数量
  batchShelveOrUnshelve: '/api/gallery/rest/galleryGoods/batchShelveOrUnshelve', // 批量上下架
  batchAddCart: '/api/gallery/rest/galleryGoods/batchAddShoppingCart', // 批量加入购物车
  getAreaQrCodeInfo: '/api/gallery/rest/galleryGoods/getAreaQrCodeInfo',
  batchCancelAdd: '/api/gallery/rest/galleryGoods/cancel/add', // 批量取消加入
  updateAreaVisible: '/api/gallery/rest/galleryGoodsArea/updateAreaVisible', // 新增分区显示/隐藏接口

  // 展厅订单
  queryGalleryList: '/api/gallery/rest/sale/order/page/list',
  queryGalleryDetail: '/api/gallery/rest/sale/order/detail',
  queryGalleryStatus: '/api/gallery/rest/sale/order/status', // 展厅订单状态

  // 到货记录
  galleryGoodsArrivalQueryPage: '/api/gallery/rest/galleryGoodsArrival/queryPage', // 查询列表
  galleryGoodsArrivalQueryDetails: '/api/gallery/rest/galleryGoodsArrival/queryDetails', // 查询子表单据明细

  // 扫码到货
  scanUniqueCode: '/api/gallery/rest/galleryGoodsArrival/scanUniqueCode', // 扫描唯一码
  getGalleryList: '/api/gallery/rest/galleryGoodsBinding/getGalleryList', // 展厅下拉列表
  bindingGallery: '/api/gallery/rest/galleryGoodsBinding/bindingGallery', // 设置所属展厅
  confirmArrival: '/api/gallery/rest/galleryGoodsArrival/confirmArrival', // 确认到货
  queryPrintInfoByRecordId: '/api/gallery/rest/galleryGoodsArrival/queryPrintInfoByRecordId', // 获取打印数据

  // 展厅退货单
  getCourierList: '/api/mall/rest/return/order/couriers', // 查询快递公司列表
  sendProduct: '/api/gallery/rest/return/order/deliver', // 确认发货
  invalidOrder: '/api/gallery/rest/return/order/cancel', // 作废
  getPickUpStation: '/api/gallery/rest/return/order/getPickUpStation', // 获取拿货站
  arrivalProduct: '/api/gallery/rest/return/order/receive', // 到货确认
  galleryReturnDetail: '/api/gallery/rest/return/order/detail', // 展厅退货单详情
  deleteGalleryOrderItem: '/api/gallery/rest/return/order/deleteOrderDetail', // 删除展厅退货单商品明细
  getGalleryReturnOrderList: '/api/gallery/rest/return/order/page/list', // 展厅列表
  getOrderStatusList: '/api/gallery/rest/return/order/getOrderStatusList', // 状态
  handlePick: '/api/gallery/rest/return/order/pickedReturnOrderDetailById/', // 操作已到货
  queryPrintUnicode: '/api/gallery/rest/return/order/queryPrintInfoByReturnOrderIds', // 请求退货单的唯一码

  // TAG 零售商分析
  queryRetailerAnalysis: '/api/boss/statistics/retailer', // 零售商分析列表
  queryRetailerList: '/api/boss/statistics/basic/retailerList', // 零售商列表

  // 缺货统计
  queryLackStatistics: '/api/mall/operation/rest/goods/lack/statistics/page', // 缺货统计分页查询

  // TAG 展厅报表
  findAllGallery: '/api/gallery/rest/findAllGallery', // 请求展厅列表
  queryGalleryGoods: '/api/boss/statistics/galleryGoods', // 展厅数据概览
  getTagList: '/api/mall/operation/rest/goods/tag/admin/list', // 标签列表
  getMarketCity: '/api/gim/rest/anon/market/findMarketCity', // 查询市场城市
  getActivityVendorList: '/api/mall/operation/rest/admin/list', // 档口列表
  findCloudWarehouseList: '/api/gim/rest/market/findCloudWarehouseList', // 网仓列表
  getPerms: '/api/iac/role/user/perms', // 获取页面权限

  getOperationShopList: '/api/mall/operation/rest/rest/common/enable/shop/item/page/list', // 分页查询已开启档口下拉列表

  // 主理人合作供应商
  getClothingAgentVendorRelation: '/api/gallery/rest/clothingAgentVendorRelation/page', // 合作供应商分页查询
  updateRemark: '/api/gallery/rest/clothingAgentVendorRelation/updateRemark', // 修改备注
  getVendorDictPage: '/api/gallery/rest/clothingAgentVendorRelation/vendorDictPage', // 合作供应商下拉列表查询
  acceptInvitation: '/api/gallery/rest/clothingAgentVendorRelation/acceptInvitation', // 供应商接受邀请
};

export default api;
