import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { EgGridModel, ImgFormatter, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { getGalleryId } from '../../utils';
import api from '../../utils/api';
import type { ScanUniqueCodeInterface, ReceiveVoInterface, IGalleryInfo } from './interface';

export default class ArrivalGoodsModel {
  constructor() {
    this.__init__();
  }

  @observable public loading = false;

  @observable public promptVisible = false;

  @observable public doesAddStatus = false;

  @observable public bindingGalleryVisible = false;

  @observable public uniqueCode: string;

  @observable public skuPic: string;

  @observable public skuNo: string;

  @observable public color: string;

  @observable public size: string;

  @observable public returnSampleCycle: string;

  @observable public galleryId: string;

  @observable public goodsId: number;

  @observable public receiveVo: ReceiveVoInterface;

  @observable public galleryOptions: Array<{ label: string;value: string | number; }> = [];

  @observable public egGridModel: EgGridModel;

  @action
  private __init__ = async() => {
    this.egGridModel = new EgGridModel({
      columns: [
        {
          key: 'uniqueCode',
          name: '唯一吗',
        },
        {
          key: 'skuPic',
          name: '图片',
          formatter: ({ row }) => {
            return <ImgFormatter value={row.skuPic}/>;
          },
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
        },
        {
          key: 'size',
          name: '尺码',
        },
      ].map((v) => ({
        frozen: true,
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'uniqueCode',
      sortByLocal: false,
      showPager: false,
      showNormalEmpty: true,
      showCheckBox: false,
    });
    this.getGalleryList();
    this.galleryId = await getGalleryId();
  };

  @action
  public async getGalleryList(): Promise<void> {
    const req = await request<BaseData<Array<{ galleryName: string;galleryId: number; }>>>({
      method: 'GET',
      url: api.getGalleryList,
    });
    this.galleryOptions = req.data.map((item) => {
      return {
        label: item.galleryName,
        value: `${item.galleryId}`,
      };
    });
  }

  @action
  public scanUniqueCode = async(): Promise<void> => {
    try {
      this.loading = true;
      const req = await request<BaseData<ScanUniqueCodeInterface>>({
        method: 'POST',
        url: api.scanUniqueCode,
        data: {
          galleryId: this.galleryId,
          uniqueCode: this.uniqueCode,
        },
      });
      if (req.data.doesAddGalley) {
        this.receiveVo = req.data?.receiveVo;
        this.doesAddStatus = req.data.doesAddStatus;
        this.promptVisible = true;
      } else {
        this.addRows(req.data.receiveVo);
        this.uniqueCode = undefined;
      }
      this.skuNo = req.data?.receiveVo?.skuNo;
      this.color = req.data?.receiveVo?.color;
      this.size = req.data?.receiveVo?.size;
      this.skuPic = req.data?.receiveVo?.skuPic;
      this.goodsId = req.data?.receiveVo?.goodsId;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  };

  @action
  public setUniqueCode = (uniqueCode: string) => {
    this.uniqueCode = uniqueCode;
  };

  @action
  public onPromptOk = () => {
    if (this.doesAddStatus) {
      this.addRows(this.receiveVo);
      this.uniqueCode = undefined;
      this.onPromptCancel();
      return;
    }
    this.promptVisible = false;
    this.bindingGalleryVisible = true;
  };

  @action
  public onPromptCancel = () => {
    this.promptVisible = false;
    this.receiveVo = undefined;
    this.doesAddStatus = false;
  };

  @action
  public onBindingGalleryOk = async(): Promise<void> => {
    if (!this.galleryId || !this.returnSampleCycle) {
      return message.error('请填写完所有项！');
    }
    const req = await request<BaseData>({
      method: 'POST',
      url: api.bindingGallery,
      data: {
        galleryId: this.galleryId,
        returnSampleCycle: this.returnSampleCycle,
        goodsIds: [this.goodsId],
      },
    });
    message.success(req.info);
    this.addRows(this.receiveVo);
    this.uniqueCode = undefined;
    this.onBindingGalleryCancel();
  };

  @action
  public onBindingGalleryCancel = () => {
    this.bindingGalleryVisible = false;
    this.returnSampleCycle = undefined;
    this.goodsId = undefined;
    this.receiveVo = undefined;
    this.doesAddStatus = false;
  };

  @action
  public setValue = (key: string, value: string | number) => {
    this[key] = value;
  };

  @action
  public addRows = (row: ReceiveVoInterface) => {
    if (this.egGridModel.rows.every((item) => item.uniqueCode !== row.uniqueCode)) {
      this.egGridModel.rows = [
        row,
        ...this.egGridModel.rows,
      ];
    }
  };

  @action
  public onConfirmArrival = async(): Promise<void> => {
    try {
      if (this.egGridModel.rows.length === 0) {
        return;
      }
      this.loading = true;
      const req = await request<BaseData>({
        method: 'POST',
        url: api.confirmArrival,
        data: {
          galleryId: this.galleryId,
          skuParams: this.egGridModel?.rows || [],
        },
      });
      message.success(req.info);
      this.uniqueCode = undefined;
      this.receiveVo = undefined;
      this.skuPic = undefined;
      this.skuNo = undefined;
      this.color = undefined;
      this.size = undefined;
      this.returnSampleCycle = undefined;
      this.goodsId = undefined;
      this.doesAddStatus = false;
      this.egGridModel.rows = [];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  };
}
